<template>
  <v-col cols="12" class="pt-4 pl-0 pl-sm-8">
    <PickupDetail :show="pickupRequest.show" :product-data="productData" :detail="pickupRequest.detail" @close="PICKUP_GET(), pickupRequest.show = false" />
    <div class="py-1">
      <div class="d-flex flex-column fill-width justify-center align-center py-10 secondary--text" @click.prevent="ShowPickupRequest()">
        <v-icon large class="mb-2" color="secondary">
          mdi-truck
        </v-icon>
        Pickup Request
      </div>
      <v-card v-if="!pickup.data.length" flat class="d-flex fill-width justify-center align-center pa-4 rounded-lg mb-4 white grey--text caption">
        - No data -
      </v-card>
      <v-expansion-panels flat>
        <v-expansion-panel
          v-for="(p, iP) in pickup.data"
          :key="'pickup-' + iP"
          class="my-2"
        >
          <v-expansion-panel-header :color="$StatusPickup(p).color" class="d-flex fill-width justify-space-between align-center rounded-lg" @click.prevent="OpenDetail(Object.assign({}, p))">
            <div class="d-flex flex-column fill-width pt-1 white--text">
              <strong class="pb-1">{{ p.customer_detail }}</strong>
              <div class="d-flex flex-column fill-width caption">
                <!-- <div>
                  {{ p.shipping_master_count }} items
                </div> -->
                <div class="caption pr-4 white--text font-weight-bold" v-html="$StatusPickup(p).text" />
                <v-divider vertical class="mx-2" />
                {{ p.shipping_master_count || '-' }} paket, {{ p.notes || '-' }}
                <div v-if="p.payments" class="d-flex align-center justify-space-between text-capitalize" @click.stop>
                  <div>
                    Pembayaran : {{ $PaymentDetail(p.payments).paymentType }} {{ $price($PaymentDetail(p.payments).amount) }} <span class="text-lowercase">dari</span> {{ $price(p.price_total) }}
                  </div>
                  <a v-if="$PaymentDetail(p.payments).paymentLink" :href="$PaymentDetail(p.payments).paymentLink" target="_blank" depressed x-small class="primary white--text d-inline-block px-4 rounded-pill" color="primary" dark>
                    Invoice
                  </a>
                </div>
              </div>
            </div>
            <!-- <div class="caption pr-4 white--text font-weight-bold">
              {{ $StatusPickup(p).text }}
            </div> -->
          </v-expansion-panel-header>
          <v-expansion-panel-content class="white black--text pt-4 rounded-bl-lg rounded-br-lg">
            <div>
              <v-select
                v-model.number="selected.cabang"
                :disabled="parseInt(p.status) ? true : false"
                :items="$store.state.storeData.filter(r => !parseInt(r.type))"
                item-text="name"
                item-value="id"
                outlined
                dense
                hide-details
                class="mb-4"
                label="Pilih Cabang"
              />
              <v-textarea
                v-model="selected.pickup_address"
                :disabled="parseInt(p.status) ? true : false"
                rows="2"
                outlined
                dense
                hide-details
                class="mb-4"
                label="Alamat penjemputan"
              />
              <v-autocomplete
                v-if="$store.state.user.store === parseInt(selected.cabang)"
                v-model.number="selected.courier"
                :search-input.sync="dataCourierQuery"
                :items="dataCourier"
                item-value="id"
                item-text="user_detail"
                outlined
                dense
                hide-details
                label="Assign Pickup Courier"
                clearable
                class="mb-4"
                autocomplete="disabled"
              />
              <v-btn v-if="!parseInt(p.status)" depressed block color="secondary" class="text-capitalize font-weight-bold" @click.prevent="PICKUP_PROCESS(p)">
                {{ $store.state.user.store === parseInt(selected.cabang) ? 'Konfirmasi Pickup' : 'Ganti Cabang' }}
              </v-btn>
              <div v-else-if="parseInt(p.status) === 2 && !p.payments" class="text-capitalize text-center pa-2 grey rounded font-weight-bold white--text">
                Menunggu Update Kiriman Kurir
              </div>
              <v-btn v-else-if="parseInt(p.status) === 3" :disabled="parseInt(selected.courier) ? false : true" depressed block color="secondary" class="text-capitalize font-weight-bold" @click.prevent="PICKUP_PROCESS(p, 'complete')">
                {{ 'Konversi ke Data Pengiriman' }}
              </v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </v-col>
</template>

<script>
let timeOutCourier = null
export default {
  props: {
    productData: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    pickup: {
      data: [],
      total: 0
    },
    pickupRequest: {
      show: false,
      detail: null
    },
    selected: {
      cabang: -1,
      pickup_address: '',
      courier: 0
    },
    dataCourierQuery: '',
    dataCourier: []
  }),
  watch: {
    dataCourierQuery (v) {
      if (!v) {
        return false
      }
      if (timeOutCourier) {
        clearTimeout(timeOutCourier)
      }
      timeOutCourier = setTimeout(() => {
        this.COURIER_GET(true)
      }, 300)
    }
  },
  mounted () {
    this.PICKUP_GET()
  },
  methods: {
    ShowPickupRequest (item) {
      this.pickupRequest.detail = item ? Object.assign({}, item) : {
        customer: parseInt(this.$store.state.user.id),
        courier: 0,
        cabang: -1,
        price_total: 0,
        pickup_address: '',
        notes: '',
        img: null,
        status: 0
      }
      this.pickupRequest.show = true
    },
    OpenDetail (pickup) {
      if (pickup) {
        this.dataCourier = []
        this.selected.cabang = parseInt(pickup.cabang) > -1 ? parseInt(pickup.cabang) : -1
        this.selected.pickup_address = pickup.pickup_address || ''
        this.selected.courier = parseInt(pickup.courier) || 0
        if (pickup.courier_detail) {
          this.dataCourier.push({ id: parseInt(pickup.courier), user_detail: pickup.courier_detail || 'No Name' })
        }
      }
    },
    PICKUP_PROCESS (pickup, act) {
      const data = act === 'complete' ? {
        id: parseInt(pickup.id) || 0,
        status: 4
      } : {
        id: parseInt(pickup.id) || 0,
        cabang: parseInt(this.selected.cabang),
        pickup_address: pickup.pickup_address || '',
        courier: parseInt(this.selected.courier) || 0,
        status: parseInt(pickup.status) || 0
      }
      let confirmText = 'Konfirmasi Pickup ?'
      if (!parseInt(data.status)) {
        if (!data.pickup_address) {
          this.$store.dispatch('TOAST', { show: true, message: 'Alamat harus diisi!' })
          return false
        }
        if (parseInt(this.$store.state.user.store) !== parseInt(data.cabang)) {
          confirmText = 'Yakin akan mengganti cabang ?'
          data.courier = 0
          data.status = 0
        } else if (!parseInt(data.courier)) {
          this.$store.dispatch('TOAST', { show: true, message: 'Kurir pickup belum dipilih!' })
          return false
        } else {
          data.status = (parseInt(data.status) || 0) + 1
        }
      }
      if (act === 'complete') {
        confirmText = 'Masukkan ' + (pickup.shipping_master_count) + ' kiriman sebagai resi?'
      }
      if (!confirm(confirmText)) {
        return false
      }
      this.$store.dispatch('logistic/PICKUP_PROCESS', data)
        .then((res) => {
          if (res.status) {
            this.$store.dispatch('TOAST', { show: true, message: 'Pickup berhasil diupdate!' })
            this.PICKUP_GET()
            if (act === 'complete') {
              this.$emit('reload-shipping', true)
            }
          }
        })
    },
    PICKUP_GET () {
      this.pickup.data = []
      this.pickup.total = 0
      this.$emit('pickup-total', 0)
      const cabang = this.$store.state.user.store
      if (parseInt(cabang) === 0 || parseInt(cabang) > 0) {
        this.$store.dispatch('logistic/PICKUP_GET', '?cabang=' + cabang)
          .then((res) => {
            if (res.status) {
              this.pickup.data = res.data.data.data || []
              this.pickup.total = parseInt(res.data.data.total) || 0
              this.$emit('pickup-total', this.pickup.total)
            }
          })
      }
    },
    COURIER_GET () {
      const q = '?q=' + (this.dataPickupQuery || '')
      this.$store.dispatch('account/ACCOUNTS_GET', q).then((res) => {
        if (res.status) {
          this.dataCourier = (res.data.data.data || []).filter(r => r.role === 'courier')
        }
      })
    }
  }
}
</script>

<style>
</style>
